<template>
  <div
    class="absolute z-40 min-w-80 bg-white px-10 py-14 drop-shadow-lg  max-md:left-1/2 max-md:-translate-x-1/2 md:w-full lg:right-0"
    :class="popupPosition === 'top' ? '-top-7 -translate-y-full' : '-bottom-7 translate-y-full'"
  >
    <div class="w-full max-w-xs">
      <div
        class="absolute size-10 rotate-45 bg-white max-lg:-translate-x-1/2 max-md:left-9 max-sm:left-1/2 lg:right-9"
        :class="popupPosition === 'top' ? 'bottom-2.5 translate-y-1/2' : 'top-2.5 -translate-y-1/2'"
      />
      <FormKit
        type="form"
        :action="wifiLogin"
        method="POST"
        :actions="false"
      >
        <FormKit
          name="cmd"
          type="hidden"
          value="authenticate"
        />
        <div class="space-y-6">
          <FormKit
            type="text"
            name="user"
            :label="$t('form.activate_wifi.username')"
            validation="required"
            :validation-label="$t('form.activate_wifi.username')"
            inner-class="rounded-md border"
            input-class="px-3"
            label-class="ml-3"
          />
          <FormKit
            type="password"
            name="password"
            :label="$t('form.activate_wifi.password')"
            validation="required"
            :validation-label="$t('form.activate_wifi.password')"
            inner-class="rounded-md border"
            input-class="px-3"
            label-class="ml-3"
          />
          <UiButton
            type="submit"
            :label="$t('global.connect_wifi_button')"
            icon="ph:link-bold"
            class="w-full"
          />
        </div>
      </FormKit>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  popupPosition?: 'top' | undefined
}>();

const wifiLogin = useRuntimeConfig().public.wifiLogin;
</script>
