<template>
  <section class="bg-gray-100 py-12">
    <div class="grid-gap container grid grid-cols-12 max-sm:gap-y-6">
      <NuxtLink
        class="col-span-12 max-sm:mx-auto sm:col-span-6 lg:col-span-2"
        :to="{name: 'index'}"
      >
        <UiLogo />
      </NuxtLink>
      <div class="col-span-12 flex max-sm:justify-center sm:col-span-6 lg:col-span-3 lg:justify-center">
        <ul class="sm:space-y-3">
          <li
            v-for="(link, index) in menu"
            :key="index"
            class="max-sm:inline-block max-sm:px-2"
          >
            <NuxtLink :to="link.url">
              {{ link.label }}
            </NuxtLink>
          </li>
        </ul>
      </div>
      <div class="col-span-12 max-sm:text-center sm:col-span-6 lg:col-span-4 lg:text-right">
        <div class="max-sm:hidden">
          <div class="text-lg font-bold">
            {{ $t('footer.contact.title') }}
          </div>
          <p>{{ $t('footer.contact.text') }}</p>
        </div>
        <UiButton
          class="sm:mt-5"
          :label="$t('global.customer_service_button')"
          to="https://www.jaarbeurs.nl/contact/vragen-en-contact"
          type="tertiary"
        />
      </div>
      <div class="col-span-12 max-sm:text-center sm:col-span-6 lg:col-span-3 lg:text-right">
        <div class="max-sm:hidden">
          <div class="text-lg font-bold">
            {{ $t('footer.activate_wifi.title') }}
          </div>
          <p>{{ $t('footer.activate_wifi.text') }}</p>
        </div>
        <div class="relative">
          <UiButton
            class="sm:mt-5"
            :label="$t('global.activate_wifi_button')"
            type="primary"
            @click="showPopUpMenu"
          />
          <UiPopUp
            v-show="popUpVisible"
            popup-position="top"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts" setup>
const popUpVisible = ref(false);

const {menu} = useSofieMenu('FooterMenu');

const showPopUpMenu = () => {
  popUpVisible.value = !popUpVisible.value;
};
</script>
