<template>
  <div class="relative">
    <div class="w-full py-5 md:py-20">
      <div class="container relative h-full">
        <div
          class="
            grid h-full grid-cols-12
            after:absolute after:left-[calc(100%_-_var(--content-padding))] after:top-0 after:block
            after:h-full after:w-[--content-spacing] md:after:border-t
          "
        >
          <UiLogoLarge class="inset-0 mx-auto max-w-44 bg-white max-md:mb-5 md:absolute md:max-w-xs md:-translate-y-1/2 md:px-4" />
          <div class="max-md:col-span-12 md:col-start-2 md:col-end-13 md:border-l md:border-t md:pb-6 md:pr-[calc(100%_/_12)] md:pt-16">
            <h1 class="text-primary text-2xl font-bold max-md:mb-4 md:text-center md:text-3xl lg:text-4xl">
              {{ $route.meta.title }}
            </h1>
          </div>
        </div>
      </div>
      <slot />
    </div>

    <AppFooter />
  </div>
</template>
<script setup>
</script>
